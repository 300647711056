"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;
  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
var imprecise = function imprecise(number) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  var factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};
var decimal2sexagesimalNext = function decimal2sexagesimalNext(decimal) {
  var _decimal$toString$spl = decimal.toString().split("."),
    _decimal$toString$spl2 = _slicedToArray(_decimal$toString$spl, 2),
    pre = _decimal$toString$spl2[0],
    post = _decimal$toString$spl2[1];
  var deg = Math.abs(Number(pre));
  var min0 = Number("0." + (post || 0)) * 60;
  var sec0 = min0.toString().split(".");
  var min = Math.floor(min0);
  var sec = imprecise(Number("0." + (sec0[1] || 0)) * 60).toString();
  var _sec$split = sec.split("."),
    _sec$split2 = _slicedToArray(_sec$split, 2),
    secPreDec = _sec$split2[0],
    _sec$split2$ = _sec$split2[1],
    secDec = _sec$split2$ === void 0 ? "0" : _sec$split2$;
  return deg + "\xB0 " + min.toString().padStart(2, "0") + "' " + secPreDec.padStart(2, "0") + "." + secDec.padEnd(1, "0") + "\"";
};
var _default = decimal2sexagesimalNext;
exports.default = _default;