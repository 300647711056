"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _toRad = _interopRequireDefault(require("./toRad"));
var _toDeg = _interopRequireDefault(require("./toDeg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getRhumbLineBearing = function getRhumbLineBearing(origin, dest) {
  var diffLon = (0, _toRad.default)((0, _getLongitude.default)(dest)) - (0, _toRad.default)((0, _getLongitude.default)(origin));
  var diffPhi = Math.log(Math.tan((0, _toRad.default)((0, _getLatitude.default)(dest)) / 2 + Math.PI / 4) / Math.tan((0, _toRad.default)((0, _getLatitude.default)(origin)) / 2 + Math.PI / 4));
  if (Math.abs(diffLon) > Math.PI) {
    if (diffLon > 0) {
      diffLon = (Math.PI * 2 - diffLon) * -1;
    } else {
      diffLon = Math.PI * 2 + diffLon;
    }
  }
  return ((0, _toDeg.default)(Math.atan2(diffLon, diffPhi)) + 360) % 360;
};
var _default = getRhumbLineBearing;
exports.default = _default;