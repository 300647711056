"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getBounds = _interopRequireDefault(require("./getBounds"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getCenterOfBounds = function getCenterOfBounds(coords) {
  var bounds = (0, _getBounds.default)(coords);
  var latitude = bounds.minLat + (bounds.maxLat - bounds.minLat) / 2;
  var longitude = bounds.minLng + (bounds.maxLng - bounds.minLng) / 2;
  return {
    latitude: parseFloat(latitude.toFixed(6)),
    longitude: parseFloat(longitude.toFixed(6))
  };
};
var _default = getCenterOfBounds;
exports.default = _default;