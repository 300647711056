"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getDistance = _interopRequireDefault(require("./getDistance"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var orderByDistance = function orderByDistance(point, coords) {
  var distanceFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _getDistance.default;
  distanceFn = typeof distanceFn === "function" ? distanceFn : _getDistance.default;
  return coords.slice().sort(function (a, b) {
    return distanceFn(point, a) - distanceFn(point, b);
  });
};
var _default = orderByDistance;
exports.default = _default;