"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getBounds = function getBounds(points) {
  if (Array.isArray(points) === false || points.length === 0) {
    throw new Error("No points were given.");
  }
  return points.reduce(function (stats, point) {
    var latitude = (0, _getLatitude.default)(point);
    var longitude = (0, _getLongitude.default)(point);
    return {
      maxLat: Math.max(latitude, stats.maxLat),
      minLat: Math.min(latitude, stats.minLat),
      maxLng: Math.max(longitude, stats.maxLng),
      minLng: Math.min(longitude, stats.minLng)
    };
  }, {
    maxLat: -Infinity,
    minLat: Infinity,
    maxLng: -Infinity,
    minLng: Infinity
  });
};
var _default = getBounds;
exports.default = _default;