"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getRhumbLineBearing = _interopRequireDefault(require("./getRhumbLineBearing"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getCompassDirection = function getCompassDirection(origin, dest) {
  var bearingFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _getRhumbLineBearing.default;
  var bearing = typeof bearingFn === "function" ? bearingFn(origin, dest) : (0, _getRhumbLineBearing.default)(origin, dest);
  if (isNaN(bearing)) {
    throw new Error("Could not calculate bearing for given points. Check your bearing function");
  }
  switch (Math.round(bearing / 22.5)) {
    case 1:
      return "NNE";
    case 2:
      return "NE";
    case 3:
      return "ENE";
    case 4:
      return "E";
    case 5:
      return "ESE";
    case 6:
      return "SE";
    case 7:
      return "SSE";
    case 8:
      return "S";
    case 9:
      return "SSW";
    case 10:
      return "SW";
    case 11:
      return "WSW";
    case 12:
      return "W";
    case 13:
      return "WNW";
    case 14:
      return "NW";
    case 15:
      return "NNW";
    default:
      return "N";
  }
};
var _default = getCompassDirection;
exports.default = _default;