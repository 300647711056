"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getCoordinateKeys2 = _interopRequireDefault(require("./getCoordinateKeys"));
var _isValidLatitude = _interopRequireDefault(require("./isValidLatitude"));
var _isValidLongitude = _interopRequireDefault(require("./isValidLongitude"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var isValidCoordinate = function isValidCoordinate(point) {
  var _getCoordinateKeys = (0, _getCoordinateKeys2.default)(point),
    latitude = _getCoordinateKeys.latitude,
    longitude = _getCoordinateKeys.longitude;
  if (Array.isArray(point) && point.length >= 2) {
    return (0, _isValidLongitude.default)(point[0]) && (0, _isValidLatitude.default)(point[1]);
  }
  if (typeof latitude === "undefined" || typeof longitude === "undefined") {
    return false;
  }
  var lon = point[longitude];
  var lat = point[latitude];
  if (typeof lat === "undefined" || typeof lon === "undefined") {
    return false;
  }
  if ((0, _isValidLatitude.default)(lat) === false || (0, _isValidLongitude.default)(lon) === false) {
    return false;
  }
  return true;
};
var _default = isValidCoordinate;
exports.default = _default;