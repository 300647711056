"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var toRad = function toRad(value) {
  return value * Math.PI / 180;
};
var _default = toRad;
exports.default = _default;