"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _toRad = _interopRequireDefault(require("./toRad"));
var _toDeg = _interopRequireDefault(require("./toDeg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getGreatCircleBearing = function getGreatCircleBearing(origin, dest) {
  var destLat = (0, _getLatitude.default)(dest);
  var detLon = (0, _getLongitude.default)(dest);
  var originLat = (0, _getLatitude.default)(origin);
  var originLon = (0, _getLongitude.default)(origin);
  var bearing = ((0, _toDeg.default)(Math.atan2(Math.sin((0, _toRad.default)(detLon) - (0, _toRad.default)(originLon)) * Math.cos((0, _toRad.default)(destLat)), Math.cos((0, _toRad.default)(originLat)) * Math.sin((0, _toRad.default)(destLat)) - Math.sin((0, _toRad.default)(originLat)) * Math.cos((0, _toRad.default)(destLat)) * Math.cos((0, _toRad.default)(detLon) - (0, _toRad.default)(originLon)))) + 360) % 360;
  return bearing;
};
var _default = getGreatCircleBearing;
exports.default = _default;