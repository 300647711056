"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getDistance = _interopRequireDefault(require("./getDistance"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var isPointWithinRadius = function isPointWithinRadius(point, center, radius) {
  var accuracy = 0.01;
  return (0, _getDistance.default)(point, center, accuracy) < radius;
};
var _default = isPointWithinRadius;
exports.default = _default;