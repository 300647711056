"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toRad = _interopRequireDefault(require("./toRad"));
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _constants = require("./constants");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getAreaOfPolygon = function getAreaOfPolygon(points) {
  var area = 0;
  if (points.length > 2) {
    var lowerIndex;
    var middleIndex;
    var upperIndex;
    for (var i = 0; i < points.length; i++) {
      if (i === points.length - 2) {
        lowerIndex = points.length - 2;
        middleIndex = points.length - 1;
        upperIndex = 0;
      } else if (i === points.length - 1) {
        lowerIndex = points.length - 1;
        middleIndex = 0;
        upperIndex = 1;
      } else {
        lowerIndex = i;
        middleIndex = i + 1;
        upperIndex = i + 2;
      }
      var p1lon = (0, _getLongitude.default)(points[lowerIndex]);
      var p2lat = (0, _getLatitude.default)(points[middleIndex]);
      var p3lon = (0, _getLongitude.default)(points[upperIndex]);
      area += ((0, _toRad.default)(p3lon) - (0, _toRad.default)(p1lon)) * Math.sin((0, _toRad.default)(p2lat));
    }
    area = area * _constants.earthRadius * _constants.earthRadius / 2;
  }
  return Math.abs(area);
};
var _default = getAreaOfPolygon;
exports.default = _default;