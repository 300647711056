"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isDecimal = _interopRequireDefault(require("./isDecimal"));
var _isSexagesimal = _interopRequireDefault(require("./isSexagesimal"));
var _sexagesimalToDecimal = _interopRequireDefault(require("./sexagesimalToDecimal"));
var _constants = require("./constants");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var isValidLongitude = function isValidLongitude(value) {
  if ((0, _isDecimal.default)(value)) {
    if (parseFloat(value) > _constants.MAXLON || value < _constants.MINLON) {
      return false;
    }
    return true;
  }
  if ((0, _isSexagesimal.default)(value)) {
    return isValidLongitude((0, _sexagesimalToDecimal.default)(value));
  }
  return false;
};
var _default = isValidLongitude;
exports.default = _default;