"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _constants = require("./constants");
var _getCoordinateKey = _interopRequireDefault(require("./getCoordinateKey"));
var _toDecimal = _interopRequireDefault(require("./toDecimal"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getLatitude = function getLatitude(point, raw) {
  var latKey = (0, _getCoordinateKey.default)(point, _constants.latitudeKeys);
  if (typeof latKey === "undefined" || latKey === null) {
    return;
  }
  var value = point[latKey];
  return raw === true ? value : (0, _toDecimal.default)(value);
};
var _default = getLatitude;
exports.default = _default;