"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isDecimal = _interopRequireDefault(require("./isDecimal"));
var _isSexagesimal = _interopRequireDefault(require("./isSexagesimal"));
var _sexagesimalToDecimal = _interopRequireDefault(require("./sexagesimalToDecimal"));
var _isValidCoordinate = _interopRequireDefault(require("./isValidCoordinate"));
var _getCoordinateKeys = _interopRequireDefault(require("./getCoordinateKeys"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
var toDecimal = function toDecimal(value) {
  if ((0, _isDecimal.default)(value)) {
    return Number(value);
  }
  if ((0, _isSexagesimal.default)(value)) {
    return (0, _sexagesimalToDecimal.default)(value);
  }
  if ((0, _isValidCoordinate.default)(value)) {
    var keys = (0, _getCoordinateKeys.default)(value);
    if (Array.isArray(value)) {
      return value.map(function (v, index) {
        return [0, 1].includes(index) ? toDecimal(v) : v;
      });
    }
    return _objectSpread(_objectSpread(_objectSpread({}, value), keys.latitude && _defineProperty({}, keys.latitude, toDecimal(value[keys.latitude]))), keys.longitude && _defineProperty({}, keys.longitude, toDecimal(value[keys.longitude])));
  }
  if (Array.isArray(value)) {
    return value.map(function (point) {
      return (0, _isValidCoordinate.default)(point) ? toDecimal(point) : point;
    });
  }
  return value;
};
var _default = toDecimal;
exports.default = _default;