"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getDistance = _interopRequireDefault(require("./getDistance"));
var _robustAcos = _interopRequireDefault(require("./robustAcos"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getDistanceFromLine = function getDistanceFromLine(point, lineStart, lineEnd) {
  var accuracy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  var d1 = (0, _getDistance.default)(lineStart, point, accuracy);
  var d2 = (0, _getDistance.default)(point, lineEnd, accuracy);
  var d3 = (0, _getDistance.default)(lineStart, lineEnd, accuracy);
  var alpha = Math.acos((0, _robustAcos.default)((d1 * d1 + d3 * d3 - d2 * d2) / (2 * d1 * d3)));
  var beta = Math.acos((0, _robustAcos.default)((d2 * d2 + d3 * d3 - d1 * d1) / (2 * d2 * d3)));
  if (alpha > Math.PI / 2) {
    return d1;
  }
  if (beta > Math.PI / 2) {
    return d2;
  }
  return Math.sin(alpha) * d1;
};
var _default = getDistanceFromLine;
exports.default = _default;