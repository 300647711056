"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _toRad = _interopRequireDefault(require("./toRad"));
var _toDeg = _interopRequireDefault(require("./toDeg"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getCenter = function getCenter(points) {
  if (Array.isArray(points) === false || points.length === 0) {
    return false;
  }
  var numberOfPoints = points.length;
  var sum = points.reduce(function (acc, point) {
    var pointLat = (0, _toRad.default)((0, _getLatitude.default)(point));
    var pointLon = (0, _toRad.default)((0, _getLongitude.default)(point));
    return {
      X: acc.X + Math.cos(pointLat) * Math.cos(pointLon),
      Y: acc.Y + Math.cos(pointLat) * Math.sin(pointLon),
      Z: acc.Z + Math.sin(pointLat)
    };
  }, {
    X: 0,
    Y: 0,
    Z: 0
  });
  var X = sum.X / numberOfPoints;
  var Y = sum.Y / numberOfPoints;
  var Z = sum.Z / numberOfPoints;
  return {
    longitude: (0, _toDeg.default)(Math.atan2(Y, X)),
    latitude: (0, _toDeg.default)(Math.atan2(Z, Math.sqrt(X * X + Y * Y)))
  };
};
var _default = getCenter;
exports.default = _default;