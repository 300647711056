"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var isPointInPolygon = function isPointInPolygon(point, polygon) {
  var isInside = false;
  var totalPolys = polygon.length;
  for (var i = -1, j = totalPolys - 1; ++i < totalPolys; j = i) {
    if (((0, _getLongitude.default)(polygon[i]) <= (0, _getLongitude.default)(point) && (0, _getLongitude.default)(point) < (0, _getLongitude.default)(polygon[j]) || (0, _getLongitude.default)(polygon[j]) <= (0, _getLongitude.default)(point) && (0, _getLongitude.default)(point) < (0, _getLongitude.default)(polygon[i])) && (0, _getLatitude.default)(point) < ((0, _getLatitude.default)(polygon[j]) - (0, _getLatitude.default)(polygon[i])) * ((0, _getLongitude.default)(point) - (0, _getLongitude.default)(polygon[i])) / ((0, _getLongitude.default)(polygon[j]) - (0, _getLongitude.default)(polygon[i])) + (0, _getLatitude.default)(polygon[i])) {
      isInside = !isInside;
    }
  }
  return isInside;
};
var _default = isPointInPolygon;
exports.default = _default;