"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _toRad = _interopRequireDefault(require("./toRad"));
var _toDeg = _interopRequireDefault(require("./toDeg"));
var _constants = require("./constants");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var getBoundsOfDistance = function getBoundsOfDistance(point, distance) {
  var latitude = (0, _getLatitude.default)(point);
  var longitude = (0, _getLongitude.default)(point);
  var radLat = (0, _toRad.default)(latitude);
  var radLon = (0, _toRad.default)(longitude);
  var radDist = distance / _constants.earthRadius;
  var minLat = radLat - radDist;
  var maxLat = radLat + radDist;
  var MAX_LAT_RAD = (0, _toRad.default)(_constants.MAXLAT);
  var MIN_LAT_RAD = (0, _toRad.default)(_constants.MINLAT);
  var MAX_LON_RAD = (0, _toRad.default)(_constants.MAXLON);
  var MIN_LON_RAD = (0, _toRad.default)(_constants.MINLON);
  var minLon;
  var maxLon;
  if (minLat > MIN_LAT_RAD && maxLat < MAX_LAT_RAD) {
    var deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
    minLon = radLon - deltaLon;
    if (minLon < MIN_LON_RAD) {
      minLon += Math.PI * 2;
    }
    maxLon = radLon + deltaLon;
    if (maxLon > MAX_LON_RAD) {
      maxLon -= Math.PI * 2;
    }
  } else {
    minLat = Math.max(minLat, MIN_LAT_RAD);
    maxLat = Math.min(maxLat, MAX_LAT_RAD);
    minLon = MIN_LON_RAD;
    maxLon = MAX_LON_RAD;
  }
  return [{
    latitude: (0, _toDeg.default)(minLat),
    longitude: (0, _toDeg.default)(minLon)
  }, {
    latitude: (0, _toDeg.default)(maxLat),
    longitude: (0, _toDeg.default)(maxLon)
  }];
};
var _default = getBoundsOfDistance;
exports.default = _default;