export function metersToNauticalMiles(meters: number): number {
    return meters / 1852;
}

export function knotsToMS(knots: number): number {
    return knots / 1.944;
}

export function calculateEtaInMinutes(distance: number, speed: number | null): number | null {
    return speed == null || speed === 0 ? null : Math.round(60 * distance / speed);
}
