"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _constants = require("./constants");
var convertSpeed = function convertSpeed(metersPerSecond) {
  var targetUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "kmh";
  switch (targetUnit) {
    case "kmh":
      return metersPerSecond * _constants.timeConversion.h * _constants.distanceConversion.km;
    case "mph":
      return metersPerSecond * _constants.timeConversion.h * _constants.distanceConversion.mi;
    default:
      return metersPerSecond;
  }
};
var _default = convertSpeed;
exports.default = _default;