"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areaConversion = exports.timeConversion = exports.distanceConversion = exports.altitudeKeys = exports.latitudeKeys = exports.longitudeKeys = exports.MAXLON = exports.MINLON = exports.MAXLAT = exports.MINLAT = exports.earthRadius = exports.sexagesimalPattern = void 0;
var sexagesimalPattern = /^([0-9]{1,3})°\s*([0-9]{1,3}(?:\.(?:[0-9]{1,}))?)['′]\s*(([0-9]{1,3}(\.([0-9]{1,}))?)["″]\s*)?([NEOSW]?)$/;
exports.sexagesimalPattern = sexagesimalPattern;
var earthRadius = 6378137;
exports.earthRadius = earthRadius;
var MINLAT = -90;
exports.MINLAT = MINLAT;
var MAXLAT = 90;
exports.MAXLAT = MAXLAT;
var MINLON = -180;
exports.MINLON = MINLON;
var MAXLON = 180;
exports.MAXLON = MAXLON;
var longitudeKeys = ["lng", "lon", "longitude", 0];
exports.longitudeKeys = longitudeKeys;
var latitudeKeys = ["lat", "latitude", 1];
exports.latitudeKeys = latitudeKeys;
var altitudeKeys = ["alt", "altitude", "elevation", "elev", 2];
exports.altitudeKeys = altitudeKeys;
var distanceConversion = {
  m: 1,
  km: 0.001,
  cm: 100,
  mm: 1000,
  mi: 1 / 1609.344,
  sm: 1 / 1852.216,
  ft: 100 / 30.48,
  in: 100 / 2.54,
  yd: 1 / 0.9144
};
exports.distanceConversion = distanceConversion;
var timeConversion = {
  m: 60,
  h: 3600,
  d: 86400
};
exports.timeConversion = timeConversion;
var areaConversion = {
  m2: 1,
  km2: 0.000001,
  ha: 0.0001,
  a: 0.01,
  ft2: 10.763911,
  yd2: 1.19599,
  in2: 1550.0031
};
exports.areaConversion = areaConversion;
areaConversion.sqm = areaConversion.m2;
areaConversion.sqkm = areaConversion.km2;
areaConversion.sqft = areaConversion.ft2;
areaConversion.sqyd = areaConversion.yd2;
areaConversion.sqin = areaConversion.in2;