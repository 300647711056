"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getLatitude = _interopRequireDefault(require("./getLatitude"));
var _getLongitude = _interopRequireDefault(require("./getLongitude"));
var _toRad = _interopRequireDefault(require("./toRad"));
var _toDeg = _interopRequireDefault(require("./toDeg"));
var _constants = require("./constants");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var computeDestinationPoint = function computeDestinationPoint(start, distance, bearing) {
  var radius = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 6371000;
  var lat = (0, _getLatitude.default)(start);
  var lng = (0, _getLongitude.default)(start);
  var delta = distance / radius;
  var theta = (0, _toRad.default)(bearing);
  var phi1 = (0, _toRad.default)(lat);
  var lambda1 = (0, _toRad.default)(lng);
  var phi2 = Math.asin(Math.sin(phi1) * Math.cos(delta) + Math.cos(phi1) * Math.sin(delta) * Math.cos(theta));
  var lambda2 = lambda1 + Math.atan2(Math.sin(theta) * Math.sin(delta) * Math.cos(phi1), Math.cos(delta) - Math.sin(phi1) * Math.sin(phi2));
  var longitude = (0, _toDeg.default)(lambda2);
  if (longitude < _constants.MINLON || longitude > _constants.MAXLON) {
    lambda2 = (lambda2 + 3 * Math.PI) % (2 * Math.PI) - Math.PI;
    longitude = (0, _toDeg.default)(lambda2);
  }
  return {
    latitude: (0, _toDeg.default)(phi2),
    longitude: longitude
  };
};
var _default = computeDestinationPoint;
exports.default = _default;