"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _constants = require("./constants");
var convertArea = function convertArea(squareMeters) {
  var targetUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "m";
  var factor = _constants.areaConversion[targetUnit];
  if (factor) {
    return squareMeters * factor;
  }
  throw new Error("Invalid unit used for area conversion.");
};
var _default = convertArea;
exports.default = _default;