import { LngLat } from "mapbox-gl";
import { Feature, FeatureCollection, GeoJsonProperties, LineString, Point, Polygon } from "geojson";
import { VesselData } from "./vessel-data";
import { CameraInfo, PointOfInterestInfo, StationFenceInfo } from "./types";

export function createLineString(points: readonly LngLat[], properties: GeoJsonProperties = {}): Feature<LineString> {
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: points.map(p => [p.lng, p.lat])
        },
        properties
    };
}

export function createPolygon(points: readonly { lat: number, lng: number }[]): Feature<Polygon> {
    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: [points.map(p => [p.lng, p.lat])]
        },
        properties: {}
    };
}

export function createVesselFeatures(vessels: readonly VesselData[]): VesselFeatureCollection {
    return {
        type: "FeatureCollection",
        features: vessels.map(vessel => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: vessel.location.toArray()
            },
            properties: {
                mmsi: vessel.mmsi,
                title: vessel.displayedTitle,
                heading: vessel.heading,
                iconImage: vessel.iconImage,
                hasPilotage: vessel.pilotage != null
            }
        }))
    };
}

export function createPointOfInterestFeatures(points: readonly PointOfInterestInfo[]): PointOfInterestFeatureCollection {
    return {
        type: "FeatureCollection",
        features: points.map(point => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [point.point.lng, point.point.lat]
            },
            properties: {
                name: point.name,
                icon: point.icon ?? "dot-10"
            }
        }))
    };
}

export function createCameraFeatures(cameras: readonly CameraInfo[]): CameraFeatureCollection {
    return {
        type: "FeatureCollection",
        features: cameras.map(camera => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [camera.point.lng, camera.point.lat]
            },
            properties: {
                id: camera.id,
                name: camera.name,
            }
        }))
    };
}

export type VesselFeature = Feature<Point, VesselFeatureProperties>;
export type VesselFeatureCollection = FeatureCollection<Point, VesselFeatureProperties>;
export type PointOfInterestFeatureCollection = FeatureCollection<Point, PointOfInterestProperties>;
export type CameraFeature = Feature<Point, CameraFeatureProperties>;
export type CameraFeatureCollection = FeatureCollection<Point, CameraFeatureProperties>;

export interface PointOfInterestProperties {
    name: string;
    icon: string;
}

export interface CameraFeatureProperties {
    id: string;
    name: string;
}

export interface VesselFeatureProperties {
    mmsi: number;
    title: string;
    heading: number;
    iconImage: string;
    hasPilotage: boolean;
}

export function createVesselLeaders(vessels: readonly VesselData[], projectedCourseMinutes: number): FeatureCollection<LineString> {
    const lines = [];

    if (projectedCourseMinutes !== 0) {
        for (const vessel of vessels) {
            const destination = vessel.destination(projectedCourseMinutes);
            if (destination != null)
                lines.push(createLineString([vessel.location, destination]));
        }
    }

    return {
        type: "FeatureCollection",
        features: lines
    };
}

export function createStationFences(fences: StationFenceInfo[]): FeatureCollection<Polygon> {
    return {
        type: "FeatureCollection",
        features: fences.map(it => createPolygon(it.points))
    };
}
