"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var isDecimal = function isDecimal(value) {
  var checkedValue = value.toString().trim();
  if (isNaN(parseFloat(checkedValue))) {
    return false;
  }
  return parseFloat(checkedValue) === Number(checkedValue);
};
var _default = isDecimal;
exports.default = _default;