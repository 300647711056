"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderByDistance = _interopRequireDefault(require("./orderByDistance"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var findNearest = function findNearest(point, coords) {
  return (0, _orderByDistance.default)(point, coords)[0];
};
var _default = findNearest;
exports.default = _default;